import React from 'react';

const Confirmation = () => {
  return(
    <div id="confirmation" className="hideConfirmation">
      <h1>Thank you for submitting a message!</h1>
      <p>I will reach back out to you as soon as I can!</p>
    </div>
  );
}

export default Confirmation;
