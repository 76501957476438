import React from 'react';
import { Consumer } from './Context';

const About = () => {
  return(
    <Consumer>
      { ({about}) => {
        return(
          <section id="about">
            <h1 className="title">About</h1>

            <section id="about-intro">
              <img className="about-svg" src={about.me} alt="Logo"></img>
              <p>Hi. I'm a web designer/developer based in Atlanta. I have a passion for crafting dynamic front-end and back-end solutions.</p>
              <a href="#contact">Let's connect</a>
            </section>

            <section id="about-animation">
              <ul className="about-icons">
                <li className="icon one">
                  <img className="rotate hover" src={about.innovative} alt="innovative logo"></img>
                  <label>Innovative</label>
                </li>
                <li className="icon two">
                  <div className="fluid-ball"></div>
                  <div className="small-ball"></div>
                  <label>Fluid</label>
                </li>
                <li className="icon three">
                  <div className="phone">
                    <div className="phone-top">
                      <div className="desktop-green-button"></div>
                      <div className="desktop-yellow-button"></div>
                      <div className="desktop-red-button"></div>
                    </div>
                    <div className="screen"></div>
                    <div className="phone-bottom">
                      <div className="phone-btn"></div>
                    </div>
                  </div>
                  <label>Responsive</label>
                </li>
              </ul>
            </section>

          </section>
        );
      }}
    </Consumer>
  );
}

export default About;
