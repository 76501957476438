import React, { Component } from 'react';
import Form from './Form';
import Confirmation from './Confirmation';

class Contact extends Component {

  state = {
    name: '',
    email: '',
    message: '',
    error: [],
  }

  render() {

    const {
      name,
      email,
      message,
      errors
    } = this.state;

    return(
      <section id="contact">
        <div className="background-arrow">
          <h1 className="title">Contact</h1>

          <div className="message">
            <p>Did you have a question or want to collaborate on a project?</p>
            <p>Feel free to submit a message!</p>
          </div>

          <div className="form-holder">

            <Form
              errors={errors}
              submit={this.submit}
              elements={() => (
                <React.Fragment>
                  <div className="form-group" >
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      onChange={this.change}
                      placeholder="John Doe"
                      className="form-control form-valid-margin" />
                    <div className="invalid-feedback">
                      You must enter a name.
                    </div>
                  </div>
                  <div className="form-group" >
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.change}
                      placeholder="name@example.com"
                      className="form-control form-valid-margin" />
                    <div className="invalid-feedback">
                      You must enter a valid email address.
                    </div>
                  </div>
                  <div className="form-group" >
                    <textarea
                      id="message"
                      name="message"
                      value={message}
                      onChange={this.change}
                      placeholder="Message"
                      className="form-control form-valid-margin"
                      rows="3" />
                    <div className="invalid-feedback">
                      You must enter at least 5 words.
                    </div>
                  </div>
                </React.Fragment>
              )}
            />

            <Confirmation />

          </div>


        </div>
      </section>
    );
  }

  change = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(() => {
      return {
        [name]: value
      };
    });
  }

  highlightError = (id, e) => {
    const element = document.getElementById(id);
    console.log(element);
    element.classList.add('is-invalid');
    element.classList.remove('is-valid');
    element.classList.remove('form-valid-margin');
  }

  removeError = (id, e) => {
    const element = document.getElementById(id);
    element.classList.remove('is-invalid');
    element.classList.add('is-valid');
    element.classList.add('form-valid-margin');
  }

  validateField = (field, limit, e, id) => {
    if (limit.test(field) === false) {
      this.highlightError(id, e);
      return true;
    } else {
      this.removeError(id, e);
      return false;
    }
  }


  validator = (e) => {
    let check = 0;

    //Validate Name field
    if (this.validateField(this.state.name, /\w* /, e, 'name')) check += 1;

    //Validate email field
    if (this.validateField(this.state.email, /^[^@]+@[^@.]+\.[a-z]{2,}$/i, e, 'email')) {
      //fetch get request to see if a user already exists with email. If so, error validate
    }

    //Validate message field
    if (this.state.message.split(' ').length < 5) {
      check += 1;
      document.getElementById('message').classList.add('is-invalid');
      document.getElementById('message').classList.remove('is-valid');
      document.getElementById('message').classList.remove('form-valid-margin');
    } else {
      document.getElementById('message').classList.add('is-valid');
      document.getElementById('message').classList.remove('is-invalid');
      document.getElementById('message').classList.add('form-valid-margin');
    }



    if (check === 0) {
      return true;
    }
  }

  submit = (e) => {
    const confirmationText = document.getElementById('confirmation');
    const form = document.querySelector('.form');

    if (this.validator(e)) {
      const {
        name,
        email,
        message
      } = this.state;

      const data = {
        name,
        email,
        message
      };

      this.props.postMessage(data);

      this.setState({
        name: '',
        email: '',
        message: '',
      });


      confirmationText.classList.add('show');
      confirmationText.classList.remove('hideConfirmation');
      form.classList.add('hideForm');
    }
  }


}

export default Contact;
