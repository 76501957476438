import React from 'react';

const Skills = (props) => {
  return(
    <section id="skills">
      <h1 className="title">Skills</h1>

      <div className="my-container">

        <div className="html box">
          <h2 className="icon-title">HTML</h2>
          <ul className="skills-icons">
            <li className="skills-icon">
              <img src={props.logos.html5.img} alt={props.logos.html5.alt} className="icon-img"></img>
              <label className="skill-label">HTML 5</label>
            </li>
            <li className="skills-icon">
              <img src={props.logos.jsx.img} alt={props.logos.jsx.alt} className="icon-img"></img>
              <label className="skill-label">JSX</label>
            </li>
          </ul>
        </div>

        <div className="css box">
          <h2 className="icon-title">CSS/Design</h2>
          <ul className="skills-icons">
            <li className="skills-icon">
              <img src={props.logos.sass.img} alt={props.logos.sass.alt} className="icon-img"></img>
              <label className="skill-label">SASS</label>
            </li>
            <li className="skills-icon">
              <img src={props.logos.bootstrap.img} alt={props.logos.bootstrap.alt} className="icon-img"></img>
              <label className="skill-label">Bootstrap</label>
            </li>
            <li className="skills-icon">
              <img src={props.logos.adobe_xd.img} alt={props.logos.adobe_xd.alt} className="icon-img"></img>
              <label className="skill-label">Adobe Xd</label>
            </li>
          </ul>
        </div>

        <div className="javascript box">
          <h2 className="icon-title">Javascript</h2>
          <ul className="skills-icons">
            <li className="skills-icon">
              <img src={props.logos.node.img} alt={props.logos.node.alt} className="icon-img"></img>
              <label className="skill-label">Node js</label>
            </li>
            <li className="skills-icon">
              <img src={props.logos.react.img} alt={props.logos.react.alt} className="icon-img"></img>
              <label className="skill-label">React</label>
            </li>
            <li className="skills-icon">
              <img src={props.logos.jquery.img} alt={props.logos.jquery.alt} className="icon-img"></img>
              <label className="skill-label">jQuery</label>
            </li>
          </ul>
        </div>

        <div className="backend box">
          <h2 className="icon-title">Back-end</h2>
          <ul className="skills-icons">
            <li className="skills-icon">
              <img src={props.logos.mongo.img} alt={props.logos.mongo.alt} className="icon-img"></img>
              <label className="skill-label">MongoDB</label>
            </li>
          </ul>
        </div>

      </div>
    </section>
  );
}

export default Skills;
