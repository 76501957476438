import React from 'react';


const Navbar = () => {
  return(
    <React.Fragment>
      <p className="intro-message anim-typewriter">Hello, Welcome to my website. </p>

      <div className="space"></div>
      <a href="#about" className="see-work">
        <p className="btn-text">See my work</p>
        <div className="work-btn">
          <div className="btn-line left"></div>
          <div className="btn-line right"></div>
        </div>
      </a>
    </React.Fragment>
  );
}

export default Navbar;
