import React from 'react';


const Navbar = (props) => {
  return(
    <nav onClick={props.openNav} id="navbar">
      <h1 className="logo">Amarius Jones</h1>
      <div className="hamburger">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className="nav-links">
        <li className="cool-link"><a className="nav-link nav-item" href="#canvas">Home</a></li>
        <li className="cool-link"><a className="nav-link nav-item" href="#about">About</a></li>
        <li className="cool-link"><a className="nav-link nav-item" href="#skills">Skills</a></li>
        <li className="cool-link"><a className="nav-link nav-item" href="#projects">Projects</a></li>
        <li className="cool-link"><a className="nav-link nav-item" href="#contact">Contact</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
