import React, { Component } from 'react';
import Rain from './Rain';
import Navbar from './Navbar';
import Intro from './Intro';
import About from './About';
import Skills from './Skills.js';
import Projects from './Projects';
import Contact from './Contact';
import Footer from './Footer';
import NotFound from './NotFound';

import $ from 'jquery';


//Images
import adobe_xd from './Context/images/skills/adobexd_logo.svg';
import bootstrap from './Context/images/skills/bootstrap_logo.svg';
import html5 from './Context/images/skills/html5.svg';
import jquery from './Context/images/skills/jquery_logo.svg';
import jsx from './Context/images/skills/jsx.svg';
import mongo from './Context/images/skills/mongo.svg';
import react from './Context/images/skills/react_logo.svg';
import node from './Context/images/skills/node_logo.svg';
import sass from './Context/images/skills/sass_logo.svg';
import me from './Context/images/me-desktop.svg';
import innovative from './Context/images/innovative.svg';




class App extends Component {

  state = {
       logos: {
         adobe_xd: {
           img: adobe_xd,
           alt: "adobe xd logo"
         },
         bootstrap: {
           img: bootstrap,
           alt: "bootstrap logo"
         },
         html5: {
           img: html5,
           alt: "html5 logo"
         },
         jquery: {
           img: jquery,
           alt: "jquery logo"
         },
         jsx: {
           img: jsx,
           alt: "jsx logo"
         },
         mongo: {
           img: mongo,
           alt: "mongo logo"
         },
         node: {
           img: node,
           alt: "node.js logo"
         },
         react: {
           img: react,
           alt: "react logo"
         },
         sass: {
           img: sass,
           alt: "sass logo"
         }
        },
      about: {
         me: me,
         innovative: innovative
     },
     width: window.innerWidth
  };


  componentDidMount() {
    this.makeItRain();
    this.animate();
    this.resize();
  }

  componentWillUnmount() {
    this.makeItRain();
    this.animate();
    this.resize();
  }

  makeItRain = () => {
    //clear out everything by removing all child nodes for the front and back rain divs
    $('.rain').empty();

    var increment = 0;
    var drops = "";
    var backDrops = "";

    while (increment < 100) {
      //random numbers to use for various randomizations

      //random number bewteen 98 and 1
      var randoHundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1));

      //random number between 5 and 2
      var randoFiver = (Math.floor(Math.random() * (5 - 2 + 1) + 2));

      //increment
      increment += randoFiver;

      //add in a new rain drop with various randomizations to certain CSS properties
      drops += '<div class="drop" style="left: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
      backDrops += '<div class="drop" style="right: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
    }

    $('.rain.front-row').append(drops);
    $('rain.back-row').append(backDrops);
  }



  openNav = (event) => {
    const navLinks = document.querySelector('.nav-links');
    const links = document.querySelectorAll('.nav-links li');
    const buttons = document.querySelectorAll('.btn-line');
    const hamburger = document.querySelector('.hamburger')

    if (window.innerWidth < 769) {
      navLinks.classList.toggle('open');
      hamburger.classList.toggle('open');
      links.forEach(link => {
        link.classList.toggle("fade");
      });
      $.map(buttons, function (button) {
        button.classList.toggle('open');
      })
    }


  }



  animate = () => {

    //All section heights
    const canvasHeight = $('.canvas')[0].clientHeight;
    const aboutHeight = $('#about')[0].clientHeight;
    const skillsHeight = $('#skills')[0].clientHeight;
    const projectsHeight = $('#projects')[0].clientHeight;
    const contactHeight = $('#contact')[0].clientHeight;

    //For about section
    const aboutTitle = $('#about .title')[0];
    const intro = $('#about-intro')[0];
    const animations = $('#about-animation')[0];

    //For skills section
    const skillsTitle = $('#skills .title')[0];
    const skillsContainer = $('#skills .my-container')[0];
    const htmlBox = $('#skills .html')[0];
    const cssBox = $('#skills .css')[0];
    const javascriptBox = $('#skills .javascript')[0];
    const backendBox = $('#skills .backend')[0];

    //For project section
    const projectTitle = $('#projects .title')[0];
    const projectContainer = $('#projects .project-container')[0];
    const project1 = $('#projects .project-container')[0].children[0];
    const project2 = $('#projects .project-container')[0].children[1];
    const project3 = $('#projects .project-container')[0].children[2];

    //For contact section
    const contactTitle = $('#contact .title')[0];
    const arrow = $('#contact .background-arrow')[0];




    window.addEventListener('scroll', (e) => {
      let scrollTop = e.target.scrollingElement.scrollTop;




      //Navigation light up
      if (scrollTop >= 650) {
        $('.logo')[0].style.color = 'black';
      } else {
        $('.logo')[0].style.color = 'white';
      }



      //About section
      if (scrollTop >= canvasHeight  - 500) {
        aboutTitle.classList.remove('easing');
        intro.classList.remove('easing');
        intro.classList.remove('slide-in-left');
        animations.classList.remove('easing');
        animations.classList.remove('slide-in-right');
      } else {
        aboutTitle.classList.add('easing');
        intro.classList.add('easing');
        intro.classList.add('slide-in-left');
        animations.classList.add('easing');
        animations.classList.add('slide-in-right');
      }

      if (this.state.width < 769) {
        if (scrollTop >= canvasHeight  - 300) {
          aboutTitle.classList.remove('easing');
          intro.classList.remove('easing');
          intro.classList.remove('slide-in-left');
        } else {
          aboutTitle.classList.add('easing');
          intro.classList.add('easing');
          intro.classList.add('slide-in-left');
        }
      }


      //Skills section

      if (this.state.width > 768) {

        if (scrollTop >= canvasHeight + aboutHeight - 500) {
          skillsTitle.classList.remove('easing');
          skillsContainer.classList.remove('easing');
          htmlBox.classList.remove('slide-in-left');
          cssBox.classList.remove('slide-in-left');
          javascriptBox.classList.remove('slide-in-right');
          backendBox.classList.remove('slide-in-right');
        } else {
          skillsTitle.classList.add('easing');
          skillsContainer.classList.add('easing');
          htmlBox.classList.add('slide-in-left');
          cssBox.classList.add('slide-in-left');
          javascriptBox.classList.add('slide-in-right');
          backendBox.classList.add('slide-in-right');
        }

      } else if (this.state.width < 769) {
        let boxHeight = $('.my-container .html')[0].clientHeight;

        if (scrollTop >= canvasHeight + aboutHeight - 500) {
          skillsTitle.classList.remove('easing');
        } else {
          skillsTitle.classList.add('easing');
        }

        if (scrollTop >= canvasHeight + aboutHeight - 100) {
          htmlBox.classList.remove('easing');
          htmlBox.classList.remove('slide-in-left');
        } else {
          htmlBox.classList.add('easing');
          htmlBox.classList.add('slide-in-left');
        }



        if (scrollTop >= canvasHeight + aboutHeight + boxHeight) {
          cssBox.classList.remove('easing');
          cssBox.classList.remove('slide-in-right');
        } else {
          cssBox.classList.add('easing');
          cssBox.classList.add('slide-in-right');
        }



        if (scrollTop >= canvasHeight + aboutHeight + (boxHeight * 2) +250) {
          javascriptBox.classList.remove('slide-in-left');
          javascriptBox.classList.remove('easing');
        } else {
          javascriptBox.classList.add('slide-in-left');
          javascriptBox.classList.add('easing');
        }



        if (scrollTop >= canvasHeight + aboutHeight + (boxHeight * 4) -200) {
          backendBox.classList.remove('slide-in-right');
          backendBox.classList.remove('easing');
        } else {
          backendBox.classList.add('slide-in-right');
          backendBox.classList.add('easing');
        }
      }




      //Projects section
      if (scrollTop >= projectsHeight * 4 + 400) {
        projectTitle.classList.remove('easing');
        projectContainer.classList.remove('easing');
        project1.classList.remove('slide-in-left');
        project2.classList.remove('slide-in-right');
        project3.classList.remove('slide-in-left');
      } else {
        projectTitle.classList.add('easing');
        projectContainer.classList.add('easing');
        project1.classList.add('slide-in-left');
        project2.classList.add('slide-in-right');
        project3.classList.add('slide-in-left');
      }

      //Contact section
      if (scrollTop >= canvasHeight + aboutHeight + skillsHeight + projectsHeight + (contactHeight/2) - 100) {
        contactTitle.classList.remove('easing');
        arrow.classList.remove('easing');
        arrow.classList.remove('slide-from-top');
      } else {
        contactTitle.classList.add('easing');
        arrow.classList.add('easing');
        arrow.classList.add('slide-from-top');
      }

    });
  };


  resize = () => {

    window.addEventListener('resize', (e) => {
      let width = this.state.width;

      this.setState({
        width: window.innerWidth
      });

      console.log(width);
    });
  }


  postMessage = (data) => {
    const options = {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }

    // https://amarius-jones-api.herokuapp.com
    fetch('https://amarius-jones-api.herokuapp.com/api', options).then(response => console.log(response));
  }

  render() {
    return(
      <React.Fragment>
        <div className="App">
          <Rain/>
          <Navbar openNav={this.openNav}/>
          <Intro/>
          <About/>
          <Skills logos={this.state.logos}/>
          <Projects/>
          <Contact postMessage={this.postMessage}/>
          <Footer/>
        </div>
      </React.Fragment>
    )
  }
}

export default App;
