import React from 'react';

const Canvas = () => {
    return(
      <div id="canvas">
        <div className="canvas splat-toggle">
          <div className="rain front-row"></div>
        </div>
      </div>
    );
  }

export default Canvas;
