import React from 'react';
import linkedin from './Context/images/linkedin.svg';
import github from './Context/images/github.svg';
import email from './Context/images/email.svg';

const Footer = () => {
  return(
    <footer>
      <ul className="icons">
        <li className="icon">
          <a href="https://www.linkedin.com/in/amarius-jones-53b822143/" rel="noopener noreferrer" target="_blank">
            <img src={linkedin} alt="linkedin logo"></img>
          </a>
        </li>
        <li className="icon">
          <a href="https://github.com/amariusj" rel="noopener noreferrer" target="_blank">
            <img src={github} alt="github logo"></img>
          </a>
        </li>
        <li className="icon">
          <a href="mailto:amarius@amariusjones.com">
            <img src={email} alt="email logo"></img>
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
