import React from 'react';

const Form = (props) => {

  const {
    elements,
    errors,
    submit
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  }

  return(
    <div className="form">
      <form onSubmit={handleSubmit} action="https://amarius-jones-api.herokuapp.com/api" method="POST">
        {elements()}
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
}

export default Form;
