import React, { Component } from 'react';

import quote_generator from './Context/images/quote_generator.PNG';
import registration_site from './Context/images/registration_project.PNG';
import poppin_tweets from './Context/images/poppin_tweets.PNG'

export class Projects extends Component {

  state = {
    projects: [
      {
        id: 1,
        image: quote_generator,
        alt: '',
        title: '',
        source: ''
      },
      {
        id: 2,
        image: registration_site,
        alt: '',
        title: '',
        source: ''
      },
      {
        id: 3,
        image: poppin_tweets,
        alt: '',
        title: '',
        source: ''
      }
    ]
  }

  render() {
    return(
      <section id="projects">
        <h1 className="title">Projects</h1>

        <div className="project-container">
          <ul className="projects">
            <li className="project">
              <a href="https://registration-site.netlify.com/" target="_blank" rel="noopener noreferrer">
                <img src={registration_site} alt="Registration site project"></img>
                <div className="overlay">
                  <p className="overlay-p">Registration Site</p>
                  <p className="overlay-link">Click here to visit!</p>
                </div>
              </a>
            </li>
          </ul>

          <ul className="projects">
            <li className="project">
              <a href="https://randomized-quote-generator.netlify.com/" target="_blank" rel="noopener noreferrer">
                <img src={quote_generator} alt="Random Quote Generator project"></img>
                <div className="overlay">
                  <p className="overlay-p">Random Quote Generator</p>
                  <p className="overlay-link">Click here to visit!</p>
                </div>
              </a>
            </li>
          </ul>

          <ul className="projects">
            <li className="project">
              <a href="https://poppin-tweets.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                <img src={poppin_tweets} alt="Poppin tweets project"></img>
                <div className="overlay">
                  <p className="overlay-p">Poppin Tweets</p>
                  <p className="overlay-link">Click here to visit!</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Projects;
